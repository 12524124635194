import { memo } from 'react';
import { clsx } from 'clsx';
import styles from './Typography.module.css';
import type { TypographyProps } from './types';
import { computeTypographyClasses } from './types';
import HyphenatedText from './HyphenatedText';
/**
 * Props for Heading component.
 */
type HeadingProps = TypographyProps & {
    /**
     * Render text with h1 styles.
     */
    h1?: boolean;
    /**
     * Render text with h2 styles.
     */
    h2?: boolean;
    /**
     * Render text with h3 styles.
     */
    h3?: boolean;
    /**
     * Render text with h4 styles.
     */
    h4?: boolean;
    /**
     * Render text with h5 styles.
     */
    h5?: boolean;
    /**
     * Render text with h6 styles.
     */
    h6?: boolean;
};

/**
 * Allowed HTML components rendered for the heading.
 */
type ComponentMap = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'div';

const Heading = ({
    children,
    uppercase,
    center,
    light,
    regular,
    medium,
    bold,
    strikeThrough,
    hyphenated,
    lineHeightAsFontSize,
    letterSpacing,
    dataTestClass,
    ...variants
}: HeadingProps) => {
    const typographyClasses = computeTypographyClasses(
        {
            uppercase,
            center,
            light,
            regular,
            medium,
            bold,
            strikeThrough,
            hyphenated,
            lineHeightAsFontSize,
            letterSpacing,
        },
        styles
    );

    const Component = (Object.entries(variants).find(
        ([, value]) => value
    )?.[0] ?? 'div') as ComponentMap;

    return (
        <Component
            className={clsx(typographyClasses, {
                ...Object.fromEntries(
                    Object.entries(variants).map(([name, value]) => [
                        styles[name],
                        value,
                    ])
                ),
            })}
            data-test-class={dataTestClass}
        >
            {hyphenated ? (
                <HyphenatedText>{children}</HyphenatedText>
            ) : (
                children
            )}
        </Component>
    );
};

export default memo(Heading);
